import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ArticlePage.css'; 
import articleData from './articles.json'
import { Link } from 'react-router-dom';

const ArticlePage = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    console.log('ID: ' + id)
    const selectedArticle = articleData.find(a => a.id === id);
    setArticle(selectedArticle);
    
    console.log('Selected Article is: ', selectedArticle);
  }, [id]); 
  
  const handleContactUs = () => {
    // Trigger mailto link to open native email client
    window.location.href = 'mailto:codelux.outreach@gmail.com?subject=Contact%20Codelux';
  };

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <div className={isMobile ? "article-page-mobile" : "article-page"}>
      <h1 style={{fontSize: 28}}>{article.title}</h1>

      <div className="article-content">
        {article.content.map((section, index) => {
          if (section.type === 'header') {
            return <h2 key={index} className="article-header">{section.text}</h2>;
          } else if (section.type === 'paragraph') {
            return <p key={index} style={{fontFamily: 'sans-serif'}} className="article-paragraph">{section.text}</p>;
          }
          return null;
        })}
      </div>
      <div style={{display: 'flex'}}>
        <div style={{textAlign: 'center', width: 150, backgroundColor: '#007bff', padding: 15, borderRadius: 8, marginRight: 5,}}>
          <button className="store-button" onClick={handleContactUs}>Contact Us</button>
        </div>

        <div style={{textAlign: 'center', width: 150, backgroundColor: '#007bff', padding: 15, borderRadius: 8, marginLeft: 5}}>
          <Link style={{color: 'white'}} to="/">Home</Link>
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
