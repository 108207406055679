import React, { useState } from 'react';
import './Home.css';
import Marquee from '../../components/marquee/Marquee';
import title from '../../Assets/Title.png';
import tagline from '../../Assets/TaglineText.png';
import MarqueeMobile from '../../components/marquee/MarqueeMobile';
import { Link } from 'react-router-dom';

function HeroSection() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [showResponse, setShowResponse] = useState(false);
  const [showPortfolio, setShowPortfolio] = useState(false); // New state for portfolio visibility
  const [currentIndex, setCurrentIndex] = useState(0); // Track current portfolio index
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  const options = [
    'What is Codelux?',
    'What Services Does Codelux Provide?',
    'Schedule A Complimentary Call / Contact Us',
  ];

  const handleOptionClick = (option) => {
    if (option === 'Schedule A Complimentary Call / Contact Us') {
      // Trigger mailto link to open native email client
      window.location.href = 'mailto:codelux.outreach@gmail.com?subject=Schedule%20a%20Complimentary%20Call';
    } else {
      setSelectedOption(option);
      setShowDropdown(false);
      setShowResponse(true);
    }
  };

  const handlePortfolioClick = () => {
    setShowPortfolio(true);
    setShowResponse(false); // Hide response text when portfolio is shown
  };

  const handleContactUs = () => {
    // Trigger mailto link to open native email client
    window.location.href = 'mailto:codelux.outreach@gmail.com?subject=Contact%20Codelux';
  };

  const responses = {
    'What is Codelux?': 'Codelux is a Texas-based software company that specializes in software development and implementation. We build custom software to fit your needs from mobile apps to web and desktop applications. We provide beautiful solutions from code to masterpiece.',
    'What Services Does Codelux Provide?': 'Codelux offers development services for mobile, web, and desktop applications. In addition, we can provide maintenance, consulting, and digital marketing services as needed.',
    'Schedule A Complimentary Call / Contact Us': 'You can schedule a complimentary call with us to discuss your project or idea. We take ideas seriously and do not share, discuss, or otherwise communicate ideas that are sent to us with anyone else besides the original party.',
  };

  // Portfolio images and texts
  const portfolioData = [
    { image: require('../../Assets/Reno3.png'), title: 'Reno App', text: 'In coordination with Momentum Multifamily, we are building a new and easier way for property owners to manage their Make-Ready and Unit Renovation forms. From Portfolio-wide data at a glance, to real-time updates, the Reno app is here to help align teams and keep coordination efforts in sync. Coming Mid 2025.' },
    { image: require('../../Assets/DemoProject.png'), title: 'Tasker', text: 'Our in-house task management software. We built Tasker to better allow ourselves to track projects and their respective tasks and open items. Tasker allows users to create projects and tasks, assign items to users, message users, plan out task due dates, and more. Our future plans include integrating AI to help project managers facilitate key deliverables and ensure project completion on time.' },
    { image: require('../../Assets/Chess3.png'), title: 'Chess with Friends', text: 'We partnered with DxT LLC to create one of the more popular chess apps on the mobile app stores. The app includes daily challenges, online matchmaking, over the board play, and more.' },
    { image: require('../../Assets/Entrata.png'), title: 'Entrata Dashboard', text: 'In 2024, we built a custom dashboard for customers who utilize the Entrata software. This dashboard can provide various KPIs across your entire Portfolio so all your key data is available at a glance. The best part of this dashboard is it better allows property managers to share where they stand with investors, saving users time and headache when attempting to further raise capital.' },
  ];

  // Handle previous and next portfolio item
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? portfolioData.length - 1 : prevIndex - 1));
    console.log('Image: ' + portfolioData[currentIndex].image)
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === portfolioData.length - 1 ? 0 : prevIndex + 1));
  };

  // Handle closing the portfolio section
  const handleClosePortfolio = () => {
    setShowPortfolio(false);
  };

  return (
    <div className="hero-section-container">
      {/* Top Bar */}
      <header className="top-bar">
        <div className="top-bar-left">
          <Link to="/articles">            
            <button className="store-button">Articles</button>
          </Link>
        </div>

        {/* Centered Image in Top Bar */}
        {!isMobile && <div className="top-bar-center">
          <img src={tagline} alt="From Code To Masterpiece" className="top-bar-image" />
        </div>}

        <div className="top-bar-right">
          <button className="store-button" onClick={handleContactUs}>Contact Us</button>
        </div>
      </header>

      {/* Centered CodeLux Image and Search Bar */}
      {!showPortfolio && (
        <div className="center-content">
          <img src={title} alt="CodeLux" className="logo-image" />

          {/* Search Bar with Dropdown */}
          <div className="search-bar-container">
            <input
              type="text"
              className={`search-input ${showDropdown ? 'open-dropdown' : ''}`}
              placeholder={isMobile ? "Click for options" : "How Can We Help? Click for options."}
              onFocus={() => setShowDropdown(true)}
              onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
              onKeyDown={(e) => e.preventDefault()} // Prevent typing
            />

            {/* Dropdown Options */}
            {showDropdown && (
              <ul className="dropdown">
                {options.map((option, index) => (
                  <li key={index} onClick={() => handleOptionClick(option)}>
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Response Text */}
          {showResponse && (
            <div className="response-text">
              {responses[selectedOption]}
            </div>
          )}

          <button className="portfolio-button" onClick={handlePortfolioClick}>Our Portfolio</button>
        </div>
      )}

      {/* Portfolio Section */}
      {showPortfolio && (
        <div className="portfolio-section">
          <div className="portfolio-container">
            <div className="portfolio-background">
              {!isMobile && (<div className="portfolio-image" style={{ backgroundImage: `url(${portfolioData[currentIndex].image})` }}></div>)}
              {isMobile && (<img src={portfolioData[currentIndex].image} alt="Portfolio" className="portfolio-image" />)}
              <div className="portfolio-text">
                <h2>{portfolioData[currentIndex].title}</h2>
                <p>{portfolioData[currentIndex].text}</p>
                <div className="portfolio-navigation">
                  <button className="arrow-button" onClick={handlePrevious}>&#10094; Previous</button>
                  <button className="arrow-button" onClick={handleNext}>Next &#10095;</button>
                </div>
                <button className="close-portfolio-button" onClick={handleClosePortfolio}>Close Portfolio</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Marquee for Previous Clients */}
       <div className="marquee-section">
        {isMobile ? <MarqueeMobile /> : <Marquee />}
      </div>
    </div>
  );
}

export default HeroSection;
