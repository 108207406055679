import React, { useState, useEffect } from 'react';
import './MarqueeMobile.css';
import Momentum from '../../Assets/img-momentum.png';
import Sayan from '../../Assets/img-sayan.png';
import dxt from '../../Assets/img-dxt.png';
import daniels from '../../Assets/img-daniels.png';

function MarqueeMobile() {
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  // Update width on window resize
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="marquee">
      <div className={`client-logos ${isMobile ? 'mobile' : ''}`}>
        <p>Past Clientele</p>
        <img src={Momentum} alt="Momentum Multifamily" className="client-logo" />
        <img src={dxt} alt="DxT LLC" className="client-logo" />
        <img src={Sayan} alt="Sayan" className="client-logo" />
        <img src={daniels} alt="Daniels & Co." className="client-logo" />
      </div>
    </div>
  );
}

export default MarqueeMobile;
