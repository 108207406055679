import React from 'react';
import { Link } from 'react-router-dom';
import './ArticlePageMenu.css';
import './ArticleMenuPageMobile.css';  // Mobile styles

const ArticleMenuPage = () => {
  const articleTitles = [
    { id: '1001', title: 'The Benefits of Converting Your Website into a Mobile App' },
  ];

  return (
    <div className="article-menu-page">
      {/* Home Button */}
      <div className="home-button-container">
        <Link to="/" className="home-button">Home</Link>
      </div>

      {/* Title of the page */}
      <h1 className="article-menu-title">Articles</h1>

      {/* List of article items */}
      <div className="article-menu-container">
        {articleTitles.map((article) => (
          <div key={article.id} className="article-menu-item">
            <Link to={`/articles/${article.id}`}>{article.title}</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArticleMenuPage;
