import React from 'react';
import './Marquee.css';
import Momentum from '../../Assets/img-momentum.png';
import Sayan from '../../Assets/img-sayan.png';
import dxt from '../../Assets/img-dxt.png';
import daniels from '../../Assets/img-daniels.png';

function Marquee() {
  return (
    <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', width: '100%'}}>
      <p className="marquee-text">Past Clientele</p>
      <img src={Momentum} alt="Momentum Multifamily" className="client-logo" />
      <img src={dxt} alt="DxT LLC" className="client-logo" />
      <img src={Sayan} alt="Sayan" className="client-logo" />
      <img src={daniels} alt="Daniels & Co." className="client-logo" />
    </div>
  );
}

export default Marquee;
